.chart-text {
    fill: var(--color-base-contrast-high);
    -moz-transform: translateY(0.125em);
    -ms-transform: translateY(0.125em);
    -webkit-transform: translateY(0.125em);
    transform: translateY(0.125em);
  }
  
  .chart-number {
    font-size: 0.435rem;
    font-weight: 700;
    line-height: 1;
    text-anchor: middle;
    -moz-transform: translateY(-0.25em);
    -ms-transform: translateY(-0.25em);
    -webkit-transform: translateY(-0.25em);
    transform: translateY(-0.25em);
  }
  
  .chart-label {
    fill: var(--color-base-contrast-med);
    font-size: .115rem;
    font-weight: 400;
    text-anchor: middle;
    -moz-transform: translateY(0.7em);
    -ms-transform: translateY(0.7em);
    -webkit-transform: translateY(0.7em);
    transform: translateY(0.85em);
  }

  .donut-segment {
    animation: dash 1200ms ease-in;
    animation-delay: 600ms;
  }

  @keyframes dash {
    from {
        stroke-dasharray: 100 0;
    }
  }