.task-review-page {

    h1, .review-card-wrapper {
        margin-bottom: var(--margin-base);
    }

    .review-card-wrapper {
        background-color: var(--color-base-contrast-lowest);
        border-radius: var(--roundedness-base);
        overflow: hidden;

        .review-card-content-wrapper {
            padding: var(--padding-s);

            h4 {
                margin-bottom: var(--margin-xs);
            }

            .report-img {
                border-radius: var(--roundedness-base);
                margin-bottom: var(--margin-s);
            }

            .task-status {
                display: flex;
                align-items: center;
                gap: var(--margin-xs);

                .task-status-icon {
                    height: var(--icon-s);
                    width: var(--icon-s);
                }
            }
        }
    }
}