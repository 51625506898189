.profile-page {

    .profile-page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: var(--margin-base);

        .profile-header-text {
            margin-right: var(--margin-s);

            .page-heading,
            .profile-name {
                font-weight: 700;
            }
            
            .page-heading {
                font-size: var(--heading-xl-mobile);
            }
    
            .profile-name {
                color: var(--color-primary);
                font-size: var(--heading-xxl-mobile);
                margin-bottom: 0;
            }
        }
        
        .profile-header-img {
            position: relative;

            .profile-img {

            }

            .change-profile-btn {
                position: absolute;
                bottom: 0;
                left: 0;
                margin: 0;
            }
        }
    }

    .profile-page-content {
        .profile-content-row {
            position: relative;

            // These allow the icon wrapper to become full height and thus vertically center properly
            padding-top: var(--margin-s);
            hr {
                margin-bottom: 0;
            }

            .profile-content-label {
                font-size: var(--body-s);
                font-weight: 700;
            }

            .profile-content-value {
                font-size: var(--body-m);
                margin-top: var(--margin-xs);
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
            }

            .edit-icon-wrapper {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                display: flex;
                align-items: center;

                .edit-icon {
                    height: var(--icon-m);
                    width: var(--icon-m);
                    filter: var(--colors-svg-filter-primary);
                }
            }
        }
    }

    .sign-out-btn {
        margin-top: var(--margin-base);
        margin-bottom: 0;
    }
}

.edit-name-wrapper,
.edit-role-wrapper {
    position: relative;
}

.toaster-wrapper {
    position: absolute;
    width: calc(100vw - (var(--margin-base) * 2));
}
