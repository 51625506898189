.avatar-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-base-contrast-med);
    color: var(--color-base);
    border-radius: 50%;
    height: var(--icon-avatar);
    width: var(--icon-avatar);
    overflow: hidden;
    font-size: var(--avatar-l);
    font-weight: 700;

    &.xxl {
        height: 160px;
        width: 160px;
        font-size: var(--body-l);
    }

    &.xl {
        height: 120px;
        width: 120px;
        font-size: var(--body-l);
    }

    &.lg {
        height: 96px;
        width: 96px;
        font-size: var(--body-l);
    }

    &.md {
        height: var(--icon-l);
        width: var(--icon-l);
        font-size: var(--body-m);
    }

    &.sm {
        height: var(--icon-s);
        width: var(--icon-s);
        font-size: var(--body-xxs);
    }

    .avatar-img {
        height: inherit;
        object-fit: contain;
    }

    .amplify-image {
        width: 100%;
    }
}

.avatar-celebration-wrapper {
    position: relative;

    .avatar-wrapper {

        &::before {
            position: absolute;
            left: -70px;
            content: '';
            z-index: -1;
            width: 120px;
            height: 120px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            background: url("../../assets/images/arcade/Celebration.svg");
            background-repeat: none;
            background-size: cover;
        }

        &::after {
            position: absolute;
            right: -70px;
            content: '';
            z-index: -1;
            width: 120px;
            height: 120px;
            background: url("../../assets/images/arcade/Celebration.svg");
            background-repeat: none;
            background-size: cover;
        }
    }

    &.animated {
        animation: var(--animation-fadeInGrow);
    }
}