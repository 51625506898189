h1 {
    font-size: var(--heading-xl-mobile);
    font-weight: 700;
    color: var(--color-base-contrast-high);
    margin: 0;
}

h2 {
    font-size: var(--heading-l-mobile);
    font-weight: 700;
    color: var(--color-base-contrast-high);
    margin: 0;
}

h3 {
    font-size: var(--heading-s-mobile);
    font-weight: 700;
    color: var(--color-base-contrast-high);
    margin: 0;
}

h4,
h5,
h6 {
    margin: 0;
}

.heading-page {
    display: flex;
    padding: var(--margin-l, 32px) var(--margin-base, 24px) var(--margin-s, 16px) var(--margin-base, 24px);
    flex-direction: column;
    gap: var(--margin-s, 16px);
    align-self: stretch;

    background: var(--color-base, #FFF);
}

.heading-section {
    font-size: var(--heading-l-mobile);
    font-weight: 700;
    margin: 0;
    flex: 1 0 0;
    align-self: stretch;
}

.heading-subSection {
    font-size: var(--heading-s-mobile);
    font-weight: 700;
}

.heading-xxl {
    color: var(--color-base-contrast-high, #292929);
    font-size: var(--heading-xxl-mobile);
    font-weight: 700;
}

.heading-xl {
    color: var(--color-base-contrast-high, #292929);
    font-size: var(--heading-xl-mobile);
    font-weight: 700;
}

.heading-l {
    color: var(--color-base-contrast-high, #292929);
    font-size: var(--heading-l-mobile);
    font-weight: 700;

}

.heading-m {
    color: var(--color-base-contrast-high, #292929);
    font-size: var(--heading-m-mobile);
    font-weight: 700;
}

.heading-s {
    color: var(--color-base-contrast-high, #292929);
    font-size: var(--heading-s-mobile);
    font-weight: 700;
}

.label {
    font-family: var(--font-family-inter);
    font-size: var(--body-s);
    font-weight: 700;
}

.sub-body {
    color: var(--color-base-contrast-med, #666);
    font-size: var(--body-m);
    font-weight: 400;
}

p {
    margin: 0;

    &.l {
        font-size: var(--body-l);
    }

    &.m {
        font-size: var(--body-m);
    }

    &.s {
        font-size: var(--body-s);
    }

    &.xs {
        font-size: var(--body-xs);
    }

    &.xxs {
        font-size: var(--body-xxs);
    }

    &.bold {
        font-weight: 700;
    }

    &.black {
        font-weight: 900;
    }
}