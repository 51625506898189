.message {
    background-color: var(--color-base-contrast-lowest);
    color: var(--color-base-contrast-high);
    font-size: var(--body-s);
    width: 100% !important;
    padding: var(--padding-xs);
    border-radius: var(--roundedness-s);
    animation: var(--animation-fadeInUp);

    &.hide {
        display: none;
        animation: var(--animation-fadeOutUp);

    }
    
    &.success {
    color: var(--color-base);
    background-color: var(--color-tertiary-green);
    }
    
    // &.info {
    // }
    
    &.error {
    color: var(--color-base);
    background-color: var(--color-primary);
    }
    
    .message-body {
        font-weight: 700;
    }

    .message-close-btn {
        background-color: transparent;
        color: var(--color-base);
        opacity: 1;
        
        &:focus,
        &:focus-visible {
            box-shadow: none;
        }
    }
}