.modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100000;

    .modal-overlay {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.8);
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;

        .modal-inner-wrapper {
            position: relative;
            border-radius: var(--roundedness-l, 16px);
            background: var(--color-base, #FFF);
            width: 90%;
            min-width: var(--screen-size-width-min);
            max-width: var(--screen-size-width-max);
            max-height: 90vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &.overflow {
                overflow-y: scroll;
            }

            .modal-top-wrapper {
                display: flex;
                padding: var(--margin-s, 16px);
                justify-content: center;
                align-items: flex-start;
                align-self: stretch;
                gap: var(--padding-s, 16px);

                border-radius: var(--roundedness-l, 16px) var(--roundedness-l, 16px) 0px 0px;
                background: var(--color-primary, #DA281B);

                &.positive {
                    background: var(--color-tertiary-green, #00B191);
                }

                &.neutral {
                    background: var(--color-base-contrast-high, #292929);
                }

                &.special {
                    background: var(--color-base, #FFF);
                    border-bottom: 1px solid var(--color-base-contrast-low);
                }



                .modal-title-wrapper {
                    display: flex;
                    flex: 1 1 0;
                    align-self: stretch;
                    font-feature-settings: 'clig' off, 'liga' off;
                    color: var(--color-base, #FFF);

                    font-size: var(--body-m);
                    font-weight: 700;

                    h5 {
                        color: var(--color-base, #FFF);
                    }

                    &.special {
                        color: var(--color-base-contrast-high);
                        h5 {
                        color: var(--color-base-contrast-high);
                        }
                    }


                    .special-icon {
                        width: 24px;
                        height: 24px;
                        margin-right: 8px;
                    }
                }

                &.shout-out {
                    flex-direction: column;
                    align-items: center;
                    gap: var(--padding-s, 16px);
                    align-self: stretch;

                    .modal-title-wrapper {
                        flex-direction: column;
                        text-align: center;
                        gap: var(--padding-s, 16px);

                        .modal-title {
                            color: var(--color-base);
                        }

                        p {
                            font-size: var(--body-s);
                            font-weight: 400;
                        }
                    }

                    .close-button {
                        position: absolute;
                        top: 16px;
                        right: 16px;
                    }
                }

                &.vote {
                    background-color: var(--color-base);
                    border-bottom: var(--color-base-contrast-low) 1px solid;

                    .modal-title {
                        color: var(--color-base-contrast-high);
                    }
                }
            }

            .modal-content-wrapper {
                display: flex;
                flex-direction: column;
                align-self: stretch;
                gap: var(--padding-s, 16px);
                justify-content: center;
                align-items: flex-start;

                &.center-content {
                    align-items: center;

                }
                &.padding {
                    padding: var(--margin-s, 16px);
                }
            }
        }
    }
}