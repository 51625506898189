.dashboard-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dashboard-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;

        .dashboard-title-wrapper {
            display: flex;
            align-items: flex-start;
            align-self: stretch;
            padding: var(--margin-l, 32px) var(--margin-base, 24px) 0px var(--margin-base, 24px);
            background: var(--color-primary);

            h1 {
                position: relative;
                display: flex;
                align-items: flex-start;
                align-self: stretch;
                margin: 0;
                color: var(--color-base);
                transition: all 150ms ease;
            }

            &::after {
                position: absolute;
                content: '';
                height: 64px;
                top: 0;
                left: 0;
                background-color: var(--color-primary);
                width: 100%;
                z-index: 0;

            }
        }


        .header-curve {
            align-self: stretch;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            align-self: stretch;
            // flex-shrink: 0;
            min-width: 100%;
            margin-top: -1px;
            height: auto;
            transition: all 300ms ease;

            &.scrolled {
                height: 80px;
            }
        }
    }

    .no-challenges-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--padding-base, 24px);
        padding: var(--margin-l, 32px) var(--margin-base, 24px);

        .category-tbd-icon {
            width: 98px;
            height: 98px;
        }

        .text-block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: var(--padding-s, 16px);
            align-self: stretch;
            text-align: center;
        }
    }
}