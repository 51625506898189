.leaderboard-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--margin-base, 24px);
    padding: var(--margin-l, 32px) var(--margin-base, 24px);
    align-items: center;
    align-self: stretch;

    .leaderboard-text-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--padding-s, 16px);
        align-self: stretch;

        p {
            align-self: stretch;
        }
    }

}