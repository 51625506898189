.list-item-wrapper {
  display: flex;
  padding: var(--margin-s, 16px) var(--margin-base, 24px);
  align-items: center;
  gap: var(--padding-base, 24px);
  background-color: var(--color-base);
  border: none;
  align-self: stretch;
  text-align: left;
  transition: var(--transition-base);

  &:focus,
  &:active {
    background-color: var(--color-base-contrast-lowest);
  }

  &.task {
    padding: var(--margin-base, 24px);
    justify-content: center;
    gap: var(--padding-s, 16px);
    align-self: stretch;
  }

  &.employee {
    padding: 12px var(--margin-base, 24px);
    align-items: center;
    gap: var(--padding-s, 16px);
    align-self: stretch;
  }

  .list-item-label {
    color: var(--color-base-contrast-high, #292929);
    font-size: var(--body-m);
    font-weight: 700;
    flex: 1 0 0;
    margin: 0;
  }

  .left-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    flex: 1 0 0;

  }

  &.employee {
    .left-wrapper {
      gap: 8px;
      flex-direction: row;
      align-items: center;
    }
  }

  .right-wrapper {
    display: flex;
    width: 88px;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;

    .percentage {
      color: var(--color-base-contrast-high, #292929);
      text-align: right;
      font-size: var(--body-s);
      font-weight: 700;
      margin: 0;
    }
  }

  .store-address {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--color-base-contrast-med, #666);
    text-overflow: ellipsis;
    font-size: var(--body-m);
    font-weight: 400;
    margin: 0;
  }

}