.perfect-shift-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: var(--margin-base);

    .text-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--padding-s, 16px);
        align-self: stretch;
    }
}
