.shout-out-buttons {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: var(--padding-s, 16px);
    align-self: stretch;
    flex-wrap: nowrap;

    .button-column {
        width: 100%;
    }
}

.shout-out-complete-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--padding-s, 16px);
    align-self: stretch;

    .avatar-wrapper {
        position: relative;
        z-index: 2;
    }

    h5 {
        text-align: center;
    }
}

.emoji-anim-wrapper {
    position: absolute;
    top: 30px;
    left: 45%;

    .emoji-anim {
        position: absolute;
        z-index: 1; //change when ready to hide
        font-size: 32px;
        animation: emojiChild1 1250ms ease;
        opacity: 0;
    }
}

.emoji-anim:nth-child(2) {
    animation: emojiChild2 1250ms ease;
    animation-delay: 50ms;
}

.emoji-anim:nth-child(3) {
    animation: emojiChild3 1250ms ease;
    animation-delay: 55ms;
}

.emoji-anim:nth-child(4) {
    animation: emojiChild4 1250ms ease;
    animation-delay: 50ms;
}

.emoji-anim:nth-child(5) {
    animation: emojiChild5 1250ms ease;
    animation-delay: 59ms;
}

.emoji-anim:nth-child(6) {
    animation: emojiChild6 1250ms ease;
    animation-delay: 56ms;

}

.emoji-anim:nth-child(7) {
    animation: emojiChild7 1250ms ease;
    animation-delay: 65ms;
}

.modal-loading-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: fill-available;
    background-color: rgba(255,255,255,0.6);
    border-radius: var(--roundedness-l);
}

.loading-icon {
    position: relative;
    align-self: center;
    width: 64px;
    height: 64px;
    animation: var(--animation-grow-infinite);
    animation-iteration-count: infinite;
}

@keyframes emojiChild1 {
    0% {
        opacity: 0;
        transform: translate(0) rotate(0) scale(1);
    }

    20% {
        opacity: 1;
        transform: translate(0) rotate(0) scale(1);
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate(0) rotate(270deg) scale(2.5);
    }
}

@keyframes emojiChild2 {
    0% {
        opacity: 0;
        transform: translate(0) rotate(0) scale(1);
    }

    20% {
        opacity: 1;
        transform: translate(0) rotate(0) scale(1);
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate(-70px, 90px) rotate(270deg) scale(1.5);
    }
}

@keyframes emojiChild3 {
    0% {
        opacity: 0;
        transform: translate(0) rotate(0) scale(1);
    }

    20% {
        opacity: 1;
        transform: translate(0) rotate(0) scale(1);
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate(70px, 70px) rotate(0deg) scale(1.8);
    }
}

@keyframes emojiChild4 {
    0% {
        opacity: 0;
        transform: translate(0) rotate(0) scale(1);
    }

    20% {
        opacity: 1;
        transform: translate(0) rotate(0) scale(1);
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate(-50px, -80px) rotate(85deg) scale(1.3);
    }
}

@keyframes emojiChild5 {
    0% {
        opacity: 0;
        transform: translate(0) rotate(0) scale(1);
    }

    20% {
        opacity: 1;
        transform: translate(0) rotate(0) scale(1);
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate(32px, -70px) rotate(120deg) scale(1.3);
    }
}

@keyframes emojiChild6 {
    0% {
        opacity: 0;
        transform: translate(0) rotate(0) scale(1);
    }

    20% {
        opacity: 1;
        transform: translate(0) rotate(0) scale(1);
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate(95px, -10px) rotate(-160deg) scale(1.3);
    }
}

@keyframes emojiChild7 {
    0% {
        opacity: 0;
        transform: translate(0) rotate(0) scale(1);
    }

    20% {
        opacity: 1;
        transform: translate(0) rotate(0) scale(1);
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate(60px, -80px) rotate(120deg) scale(1.3);
    }
}