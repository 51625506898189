.insights-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--padding-base, 24px);
    overflow: visible;
    align-self: stretch;
    padding: var(--margin-l, 32px) 0;

    .time-ago {
        color: var(--color-base-contrast-med, #666);
        text-align: right;
    }


    .insights-text-block {
        padding-left: var(--padding-base);
    }

    // This needs work to get the cards to break out of padding
    .insights-carousel-wrapper {
        display: flex;
        align-items: flex-start;
        padding: 0 var(--padding-base);
        gap: var(--padding-s, 16px);
        overflow: visible;
        align-self: stretch;
        overflow-x: scroll;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .button-wrapper {
        display: flex;
        padding: 0px var(--margin-base, 24px);
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
    }

}