.select-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    
    &.reverse {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-bottom: 12px;
    }

    .select-label {
        font-family: var(--font-family-inter);
        font-size: var(--body-s);
        font-weight: 700;
    }

    .select-main {
        width: 100%;
        padding: 12px;
        border: 1px solid var(--color-base-contrast-low);
        border-radius: calc(var(--roundedness-base) / 2);
        margin: var(--margin-xxs) 0;
        position: relative;
        background-color: var(--color-base);
        text-align: left;

        &:active,
        &:focus,
        &:focus-visible {
            border: 1px solid var(--color-base-contrast-high);
            outline: none;
        }

        &.error {
            color: var(--color-primary);
            border-color: var(--color-primary);

            &::-webkit-select-placeholder {
                color: var(--color-primary);
            }
        }

        &.disabled {
            color: var(--color-med-gray);
            border-color: var(--color-base-contrast-med);
        }
    }

    .select-icon {
        position: absolute;
        right: 12px;
        top: 12px;
        transition: all 300ms ease;
    }

    .select-icon.open {
        transform: (rotate(180deg));
    }
}

.select-list {
    position: absolute;
    top: 100%;
    display: flex;
    width: 100%;
    padding: var(--padding-xs) 0px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    height: auto;
    max-height: 226px;
    overflow-y: scroll;
    z-index: 2;
    border-radius: var(--roundedness-s);
    background: var(--color-base, #FFF);
    box-shadow: var(--shadow-s);
    animation: var(--animation-fadeInUp);
}

.btn-wrapper {
    display: flex;
    align-self: stretch;
    flex: 1 0 0;
    flex-direction: column;

    .btn-main {
        margin: var(--margin-xxs);
        flex: 1 0 0;
        align-self: stretch;
        padding: 8px 12px 9px 12px;
    }
}

.select-option {
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border: none;
    padding: var(--padding-xs);
    box-sizing: border-box;
    cursor: pointer;
    outline: none;

    &:active {
        background-color: var(--color-base-contrast-lowest);
    }

    &:focus {
        background-color: var(--color-base-contrast-low);
    }
}