.input-wrapper {

    &.reverse {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-bottom: 12px;
    }

    .input-label {
        font-family: var(--font-family-inter);
        font-size: var(--body-s);
        font-weight: 700;

        &.radio-label {
            font-size: var(--body-m);
            font-weight: 400;
            margin: auto 0;
            padding-left: var(--margin-xs);
        }
    }

    .input-main {
        width: 100%;
        padding: 12px;
        border: 1px solid var(--color-base-contrast-low);
        border-radius: calc(var(--roundedness-base) / 2);
        margin: var(--margin-xxs) 0;

        &:active,
        &:focus,
        &:focus-visible {
            border: 1px solid var(--color-base-contrast-high);
            outline: none;
        }

        &.error {
            color: var(--color-primary);
            border-color: var(--color-primary);

            &::-webkit-input-placeholder {
                color: var(--color-primary);
            }
        }

        &.disabled {
            color: var(--color-med-gray);
            border-color: var(--color-base-contrast-med);
        }

        &.radio {
            appearance: none;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            background-color: #e1e1e1;
            padding: 8px;
            border: 4px solid #e1e1e1;

            &:checked {
                background-color: var(--color-primary);
            }
        }
    }
}

.input-footer {
    display: flex;
    justify-content: space-between;
    font-size: var(--body-s);
    color: var(--color-base-contrast-high);
    font-weight: 400;
    margin: 0;
}

.input-footer-helper {
    margin: 0;

}
    .input-footer-subLink {
    color: var(--color-primary);
    font-weight: 700;
    font-size: var(--body-s);
    margin: 0;
}