.leaderboard-list-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
    gap: var(--padding-s, 16px);
    align-self: stretch;
    margin-bottom: 0;

    &.weeklyResults {
        max-height: 220px;
        overflow-y: scroll;
        padding: 0px var(--margin-xs, 8px) var(--margin-xs, 8px) var(--margin-xs, 8px);
        margin: 0 var(--margin-xs, 8px) 24px var(--margin-xs, 8px);
    }
}

.result-firstPlace-wrapper {
    display: flex;
    padding: var(--margin-base, 24px) var(--margin-s, 16px);
    flex-direction: column;
    align-items: center;
    gap: var(--padding-s, 16px);
    align-self: stretch;



    .rank-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        p {
            font-size: 40px;
        }

        img {
            width: 40px;
            height: 40px;
        }
    }
}

.no-trophies-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: var(--padding-s);
    padding: 16px 24px 32px 24px;
    border-radius: var(--roundedness-base, 8px);
    border: 1px solid var(--color-base-contrast-low, #E1E1E1);
    align-self: stretch;
    
    img {
        width: var(--icon-avatar);
        height: var(--icon-avatar);
        margin-bottom: var(--padding-xs);
    }
    
    p {
    }
}