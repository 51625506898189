.task-card {
    width: 100%;
    background-color: var(--color-base-contrast-high);
    color: var(--color-base);
    border-radius: var(--roundedness-base);
    overflow: hidden;

    &.no-radius {
        border-radius: 0;
    }

    .task-card-header-content,
    .task-card-body {
        display: flex;
        align-items: center;
    }

    .task-card-header {
        font-size: var(--body-xs);
        color: var(--color-base);
        padding: var(--padding-container-xsmall);

        &.completed {
            background-color: var(--color-tertiary-green);

            .task-card-role-name,
            .time-limit-text {
                opacity: 1;
            }
        }

        &.rejected {
            background-color: var(--color-primary);

            .task-card-role-name,
            .time-limit-text {
                opacity: 1;
            }
        }
        
        .task-card-header-content {
            font-weight: 700;
            gap: var(--margin-xs);
            
            .current-user-tag {
                background-color: var(--color-secondary-yellow);
                color: var(--color-base-contrast-high);
                font-size: 10px;
                font-weight: 900;
                padding: 0 calc(var(--padding-xs) / 2);
                border-radius: var(--roundedness-tag);
            }

            .task-card-time-limit {
                display: inherit;
                align-items: inherit;
                gap: var(--margin-xs);

                .time-limit-alert-img {
                    height: var(--icon-s);
                    width: var(--icon-s);
                }
            }

            .task-card-completed-tag {
                background-color: var(--colors-overlay);
                border-radius: var(--roundedness-tag);
                padding: calc(var(--margin-xs) / 2) 6px;
                text-wrap: nowrap;
            }
        }

        .task-card-reject-message {
            margin-top: var(--margin-xs);
            text-align: center;
        }
    }

    .task-card-role-name,
    .time-limit-text {
        opacity: 0.6;
    }

    .task-card-role-name,
    .task-name {
        margin-right: auto;
    }

    .task-name {
        width: 100%;
        min-width: 134px;
    }
    
    .task-card-role-name,
    .time-limit-text,
    .task-name-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .task-card-body {
        font-size: var(--body-m);
        font-weight: 700;
        padding: var(--margin-s);

        .task-name,
        .task-score-modifier {
            display: inherit;
            align-items: inherit;
        }

        .task-name {
            padding-right: var(--padding-s);
            gap: var(--margin-xs);

            .task-name-image {
                height: var(--icon-l);
                width: var(--icon-l);
            }
        }

        .task-score-modifier {
            gap: calc(var(--margin-s) / 2);
    
            .modifier-img {
                height: var(--icon-s);
                width: var(--icon-s);
            }
        }
    }

    .task-card-footer {
        hr {
            margin: 0;
        }

        .main-cta-footer,
        .completed-footer {
            padding: var(--margin-s);
        }

        .main-cta-footer {
            .two-cta {
                gap: var(--margin-xs);
            }
        }
        
        .two-cta,
        .completed-footer {
            display: flex;
        }

        .task-btn {
            margin: 0;
            font-size: var(--button-s);
            color: var(--color-base-contrast-high);
    
            &.task-claim-btn,
            &.btn-disabled {
                color: var(--color-base);
            }

            &.btn-disabled:not(.footer-btn) {
                background-color: var(--color-base-contrast-med);
                border: 0;
            }
        }

        .completed-footer {
            justify-content: space-between;

            .footer-text {
                display: inherit;
                align-items: center;

                .manager-completed {
                    color: var(--color-base-contrast-low);
                    font-size: var(--body-xs);
                    margin-right: var(--margin-xs);
                }
        
                .task-shout-outs {
                    display: inherit;
                    align-items: inherit;
                    gap: var(--margin-xs);
                }
            }

            .footer-btn {
                max-width: unset;
                width: fit-content;
                height: unset;
                min-height: var(--button-height-s);
            }
        }
    }
}