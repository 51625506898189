.variant-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--padding-base);
}

.take-task-photo-wrapper {

    .take-task-photo-banner {
        font-size: var(--body-l);
        font-weight: 700;
        padding: var(--margin-s);
        background-color: var(--color-base-contrast-high);

        .task-name {
            display: flex;
            align-items: center;
            color: var(--color-base);
        }

        .task-name {
            padding-right: var(--padding-s);
            gap: var(--margin-s);

            .task-name-image {
                height: var(--icon-l);
                width: var(--icon-l);
            }
        }
    }

    .photo-input-wrapper {
        position: relative;
        height: 240px;
        border: 1px solid var(--color-secondary-yellow);
        border-radius: var(--roundedness-base);
        overflow: hidden;
        
        .photo-input-label {
            cursor: pointer;
            background: url('../../assets/images/material/Camera.svg') 50% 50% no-repeat;
            transition: all 150ms cubic-bezier(.42,.97,.52,1.49);

            &:active {
                background-color: var(--color-light-yellow);
                border-color: var(--color-dark-yellow);
                transform: scale(1.05);
            }
        }

        #photo-input-thumbnail {
            display: none; /* Prevents the alt text appearing when there is no content */
            position: absolute;
            top: 0;
            left: 0;
            object-fit: contain;
            background-color: var(--color-base-contrast-low);
        }

        .photo-input-label,
        #photo-input-thumbnail {
            height: 100%;
            width: 100%;
        }
        
        #task-photo-input,
        #hiddenText {
            visibility: hidden;
        }
    }
}

.completed-page-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--margin-l);

    & > hr {
        margin: 0;
    }
}