.stats-wrapper {
    display: flex;
    justify-content: center;
    gap: var(--padding-s, 16px);
    align-self: stretch;
}

.stat-wrapper {
    display: flex;
    padding: var(--margin-s, 16px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;

    border-radius: var(--roundedness-Base, 8px);
    border: 1px solid var(--color-base-contrast-low, #E1E1E1);
    background: var(--color-base, #FFF);

    .stat-top {
        display: flex;
        align-items: baseline;
        gap: 2px;
        align-self: stretch;
    }

    .stat-number {
        color: var(--color-base-contrast-high, #292929);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 32px;
        font-weight: 700;
        margin: 0;
    }

    .stat-outOfNumber {
        color: var(--color-base-contrast-high, #292929);
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: var(--body-xs);
        font-weight: 800;
        margin: 0;
    }

    .stat-label {
        display: flex;
        height: 32px;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
        color: var(--color-base-contrast-med, #666);
        text-overflow: ellipsis;
        font-size: var(--body-xs);
        margin: 0;
        font-weight: 400;
    }
}