.team-badge-wrapper {
    max-width: var(--screen-size-width-max);
    animation: var(--animation-fadeInGrow);
}

// --- Part 2
.st0{display:none;}
.st1{display:inline;}
.st2{fill:#FD3C4F;}
.st3{fill:#FFA500;}
.st4{opacity:0.3;fill:#FFFFFF;enable-background:new    ;}
.st5{opacity:0.15;enable-background:new    ;}
.st6{opacity:0.91;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;enable-background:new    ;}
.st7{fill:#989898;}
.st8{opacity:0.3;fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;enable-background:new    ;}
.st9{opacity:0.2;fill-rule:evenodd;clip-rule:evenodd;enable-background:new    ;}
.st10{fill-opacity:0.5;}
.st11{fill:#666666;}
.st12{fill:#C52F00;}
.st13{fill:#BF5C00;}
.st14{fill:#98C900;}
.st15{fill:#F2A863;}
.st16{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
.st17{fill:#EDC28F;}
.st18{opacity:6.000000e-02;enable-background:new    ;}
.st19{opacity:0.31;fill:#FFFFFF;enable-background:new    ;}
.st20{fill:#FFCC00;}
.st21{fill:#C44125;}
.st22{fill:#FFF9E5;}
.st23{fill:#F49B48;}
.st24{fill:#B04200;}
.st25{fill:#F9D886;}
.st26{fill:#FFFFFF;}
.st27{fill:#37D0EE;}
.st28{fill:#CD2E42;}
.st29{fill:none;stroke:#FFFFFF;stroke-width:0.375;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st30{fill:#BD6300;}
.st31{fill:#D6D1CE;}
.st32{fill-rule:evenodd;clip-rule:evenodd;fill:#FCF0DA;}
.st33{fill:none;stroke:#FFFFFF;stroke-width:0.24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st34{opacity:0.15;fill-rule:evenodd;clip-rule:evenodd;enable-background:new    ;}
.st35{fill:#292929;}
.st36{fill:#FEFEFE;}