.btn-main {
    width: 100%;
    height: var(--button-height-l);
    background-color: var(--color-secondary-yellow);
    border: 1px solid var(--color-secondary-yellow);
    border-radius: var(--roundedness-base);
    font-size: var(--button-l);
    font-weight: 800;
    font-family: var(--font-family-inter);
    vertical-align: middle;
    margin: var(--margin-xs) 0;
    transition: var(--transition-button);

    &:active {
        background-color: var(--color-dark-yellow);
        border-color: var(--color-dark-yellow);
        transform: scale(1.05);
    }

    &.btn-sm {
        // max-width: 90px;
        height: var(--button-height-s);
        font-size: var(--button-s);
    }

    &.btn-md {
        // max-width: 100px;
        height: var(--button-height-m);
        font-size: var(--button-m);
    }

    &.btn-full {
        background-color: var(--color-base);
        padding: 12.5px 0;
        border: 0;
        border-radius: 0;
    }

    &.btn-reject {
        background-color: var(--color-primary);
        border-color: var(--color-primary);

        .btn-icon {
            filter: var(--colors-svg-filter-base);
        }

        &:not(.btn-disabled) {
            color: var(--color-base);
            
            #btn-loading-spinner {
                filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(263deg) brightness(101%) contrast(101%);
            }

            &:active {
                background-color: var(--color-dark-red);
                border-color: var(--color-dark-red);
            }
            
            &.btn-ghost{
                color: var(--color-primary);

                #btn-loading-spinner {
                    filter: invert(19%) sepia(65%) saturate(3256%) hue-rotate(350deg) brightness(109%) contrast(97%);
                }
                
                .btn-icon {
                    filter: var(--colors-svg-filter-primary);
                }

                &:active {
                    background-color: var(--color-light-red);
                }
            }
        }
    }

    &.btn-disabled {
        background-color: var(--color-base-contrast-low);
        border-color: var(--color-base-contrast-low);
        color: var(--color-base-contrast-med);

        &:active {
            transform: scale(1);
        }
    }

    &.btn-ghost {
        background-color: transparent;

        &:active {
            &:not(.btn-disabled) {
                background-color: var(--color-light-yellow);
            }
        }

        &.btn-disabled {
            border: 1px solid var(--color-base-contrast-lowest);
        }
    }

    &.btn-icon-only {
        background-color: var(--color-base);
        padding: var(--margin-xs);
        border: 0 var(--color-base);
        border-radius: 50%;
        width: unset;
        height: unset;
        box-shadow: 0px 4px 12px 0px #00000040;

        .btn-content {
            .btn-icon {
                width: var(--icon-base);
                height: var(--icon-base);
                filter: var(--colors-svg-filter-primary);
            }
        }
    }

    .btn-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: var(--margin-xs);

        &.icon-right {
            flex-direction: row-reverse;
        }

        .btn-icon {
            width: var(--icon-s);
            height: var(--icon-s);
        }
    }

    #btn-loading-spinner {
        -webkit-animation: rotating 2s linear infinite;
        -moz-animation: rotating 2s linear infinite;
        -ms-animation: rotating 2s linear infinite;
        -o-animation: rotating 2s linear infinite;
        animation: rotating 2s linear infinite;

        @-webkit-keyframes rotating { /* Safari and Chrome */
            from {
              -webkit-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            to {
              -webkit-transform: rotate(360deg);
              -o-transform: rotate(360deg);
              transform: rotate(360deg);
            }
        }

        @keyframes rotating {
            from {
              -ms-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            to {
              -ms-transform: rotate(360deg);
              -moz-transform: rotate(360deg);
              -webkit-transform: rotate(360deg);
              -o-transform: rotate(360deg);
              transform: rotate(360deg);
            }
        }
    }
}