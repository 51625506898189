.task-list-page {

    hr {
        margin: 0 var(--padding-base);
    }

    .page-padding {
        
    }

    .task-progress-bar-wrapper {
        padding: var(--padding-l) var(--padding-base) 0 var(--padding-base);
    }
}

.no-tasks-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: var(--padding-s);
    
    img {
        width: var(--icon-avatar);
        height: var(--icon-avatar);
        margin-bottom: var(--padding-xs);
    }
    
    p {
        max-width: 200px;
    }
}

.details-heading {
    margin: var(--padding-base) 0;
}