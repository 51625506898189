.tasks-insights-section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding-bottom: var(--margin-l, 32px);

    .tasks-insights-top-block {
        display: flex;
        padding: var(--margin-l, 32px) var(--margin-base, 24px);
        flex-direction: column;
        align-items: center;
        gap: var(--padding-base, 24px);
        align-self: stretch;

        .leaderboard-title {
            margin-bottom: var(--padding-s);
        }

    }

    .task-list {
        display: flex;
        width: 100&;
        padding: 0 var(--margin-base, 24px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--padding-s, 16px);
        align-self: stretch;
    }

}