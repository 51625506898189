// Insights List Item
.insights-list-item-wrapper {
  display: flex;
  padding: var(--margin-s, 16px);
  align-items: center;
  gap: var(--padding-s, 16px);
  align-self: stretch;
  text-align: left;
  border: none;
  transition: var(--transition-button);

  border-radius: var(--roundedness-l, 16px);
  background: var(--color-base-contrast-high, #292929);

  .left-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
  }

  &:active {
    background: black;
    transform: scale(1.02);
  }
}

.insight-category-icon {
  display: flex;
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
}

.insights-item-label {
  align-self: stretch;
  color: var(--color-base, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  margin: 0;
  font-size: var(--body-l);
  font-weight: 700;
}

.insights-item-detail {
  align-self: stretch;
  color: var(--color-base, #FFF);
  margin: 0;
  font-size: var(--body-s);
  font-weight: 400;
}