.progress-wrapper {
    display: flex;
    align-self: stretch;
    flex-direction: column;
    align-items: center;
    gap: var(--padding-xs, 8px);

    .progress-heading {
        display: flex;
        height: 19px;
        align-items: center;
        gap: 8px;
        align-self: stretch;

        h3 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1 0 0;
            align-self: stretch;
        }

        .task-list-link {
            display: inherit;
            align-items: center;
            gap: var(--margin-xxs);
            font-size: var(--button-s);

            .link-arrow {
                height: var(--icon-s);
                width: var(--icon-s);
            }
        }
    }
        .progress-bar {
            height: 8px;
            border-radius: var(--roundedness-s, 4px);
            align-self: stretch;
            background-color: var(--color-base-contrast-low, #E1E1E1) !important;

            .progress-bar-indicator {
                height: 8px;
                border-radius: var(--roundedness-s, 4px);
                flex-shrink: 0;
                background: var(--color-tertiary-green, #00B191);
            }
        }
    }