// The below is for styling the hamburger menu
// See docs: https://jonsuh.com/hamburgers/
$hamburger-layer-color: var(--color-base);
$hamburger-active-layer-color: $hamburger-layer-color;
$hamburger-layer-width: 24px;
$hamburger-layer-height: 2px;
@import "../../assets/scss/hamburgers/hamburgers.scss";


.header-navigation {
    display: flex;
    align-items: center;
    background-color: var(--color-primary);
    padding: var(--padding-container-small);
    height: var(--main-nav-height);
    position: fixed;
    z-index: 999;
    width: 100%;

    .logo-wrapper {
        background: transparent;
        padding: 0;
        border: none;
        margin-right: auto;

        .header-logo {
            max-height: calc(var(--main-nav-height) / 2);
            margin-right: auto;
        }
    }

    .header-btn {
        height: fit-content;
        display: inline-flex;
        padding: 4px 6px;
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
        width: fit-content;
        margin: 0 16px 0 0;
        border-radius: 6px;
        border: none;

        .btn-content {
            gap: var(--margin-xxs);

            .btn-icon {
                width: 15px;
                height: 15px;
            }
        }
    }

    .hamburger-menu {
        height: var(--heading-xl-mobile);
        padding: 0;

        .hamburger-box {
            width: var(--heading-xl-mobile);
            height: var(--heading-xl-mobile);

            .hamburger-inner {
                fill: var(--color-base);
            }
        }
    }
}

.navigation-menu {
    position: fixed;
    visibility: hidden;
    top: var(--main-nav-height);
    background-color: var(--color-base);
    width: 100%;
    opacity: 0;
    transform: translateX(100%);
    height: calc(99vh - var(--main-nav-height));
    z-index: 2;
    transition: all 300ms ease-in;

    hr {
        margin: 0 var(--margin-base);
    }
}

.navigation-menu.open {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
    height: calc(100vh - var(--main-nav-height));
    transition: all 300ms ease-out;
    z-index: 999;
}

.navigation-menu .btn-main.btn-full {
    transform: translate(-100px);
    transition: all 600ms ease;
}

.navigation-menu.open .btn-main.btn-full {
    transform: translate(0);
}

.navigation-menu button:nth-child(1) {
    transition-delay: 800ms;
}

.nav-btn {
    display: flex;
    align-items: center;
    gap: var(--margin-xs);
    width: 100%;
    height: 60px;
    padding: 0 var(--margin-base) !important;
    margin: 0;
    font-weight: 400;
    font-size: var(--body-m);

    .btn-icon {
        width: var(--icon-base) !important;
        height: var(--icon-base) !important;
        margin-right: var(--margin-s);
        filter: var(--colors-svg-filter-primary);
    }

    &:active {
        background-color: var(--color-base-contrast-lowest);
    }

    &.active-page {
        font-weight: 700;
    }
}