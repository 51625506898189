.insights-page {

    .insights-page-header {
        display: flex;
        padding: var(--margin-l, 32px) var(--margin-base, 24px) var(--margin-s, 16px) var(--margin-base, 24px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--margin-s, 16px);
        align-self: stretch;

        .breadcrumb-wrapper {
            margin: 0;
        }

        .insights-header-title {
            display: flex;
            flex-direction: column;
            gap: var(--margin-s, 16px);
            align-self: stretch;

            &.icon {
                flex-direction: row;
                gap: var(--margin-xs, 8px);

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        .insights-list-wrapper {
            .insights-employee-list-wrapper {
                .employees-section-wrapper {
                    padding-top: var(--margin-use-cases-top-of-page);
                }
            }
        }
    }

    .insights-body {
        display: flex;
        padding: var(--margin-use-cases-top-of-page, 16px) var(--margin-base, 24px);
        flex-direction: column;
        align-items: center;
        gap: var(--padding-base, 24px);
        align-self: stretch;

        p {
            display: flex;
            align-items: center;
            gap: var(--margin-xs);
            align-self: flex-start;
        }
    }

    &.index {
        .insights-index-list-wrapper {
            display: flex;
            padding: var(--margin-use-cases-top-of-page, 16px) var(--margin-base, 24px) var(--margin-l, 32px) var(--margin-base, 24px);
            flex-direction: column;
            align-items: center;
            gap: var(--padding-base, 24px);
            align-self: stretch;
        }
    }



}