.login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--margin-l);
    font-family: var(--font-family-inter);
    background-color: var(--color-primary);
    min-height: 100vh;
    margin-top: 0;

    .login-card {
        background-color: var(--color-base);
        padding: var(--margin-base);
        border-radius: var(--roundedness-l);

        .login-card-header,
        .login-card-message,
        .login-page-btn {
            margin-top: 0;
            margin-bottom: var(--margin-s);
        }

        .login-card-header,
        .login-error-message {
            font-weight: 700;
        }

        .login-card-header {
            font-size: var(--heading-xl-mobile);
        }

        .login-card-message,
        .login-footer {
            color: var(--color-base-contrast-med);
        }

        .login-card-message {
            font-size: var(--body-m);
            word-break: break-word;
        }

        .login-page-input-wrapper,
        .login-error-message,
        .resend-btn-wrapper {
            margin-bottom: var(--margin-xs);
        }

        .login-page-input-wrapper {
            padding-bottom: 1px; /* This adds a place for the margin push to (collapsing margins). Check: https://stackoverflow.com/questions/27829250/why-doesnt-a-childs-margin-affect-a-parents-height */
        }

        .login-error-message,
        .resend-btn-wrapper .resend-btn {
            font-size: var(--body-xs);
        }

        .login-error-message {
            width: 100%;
            background-color: var(--color-primary);
            color: var(--color-base);
            padding: var(--margin-xs);
            border-radius: var(--roundedness-s);
        }

        .resend-btn-wrapper {
            text-align: right;
            
            .resend-btn {
                color: var(--color-primary);
                background: transparent;
                font-weight: 700;
                padding: 0;
                border: 0;
                margin: 0;
            }
        }

        .login-footer {
            font-size: var(--body-s);
        }
    }
}