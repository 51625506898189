.next-task-wrapper {
    width: 100%;
    
    .next-task-heading {
        display: flex;
        justify-content: space-between;
        font-size: var(--body-xs);
        font-weight: 700;
        margin-bottom: var(--margin-base);

        .task-list-link {
            display: inherit;
            align-items: center;
            gap: var(--margin-xxs);

            .link-arrow {
                height: var(--icon-s);
                width: var(--icon-s);
            }
        }
    }
}