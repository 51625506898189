.divider {
    height: 1px;
    width: 100%;
    background: var(--color-base-contrast-low, #E1E1E1);
    margin: 0;
    opacity: 1;
    border-style: none;

    &.padding {
      margin: 0 var(--padding-base);
    }
}