.toast {
    background-color: var(--color-base-contrast-med);
    color: var(--color-base) !important;
    font-size: var(--body-s);
    width: 100% !important;
    
    &.success {
        background-color: var(--color-tertiary-green);
    }
    
    // &.info {
    // }
    
    &.error {
        background-color: var(--color-primary);
    }
    
    .toast-body {
        font-weight: 700;
    }

    .toast-close-btn {
        background-color: transparent;
        color: var(--color-base);
        opacity: 1;
        
        &:focus,
        &:focus-visible {
            box-shadow: none;
        }
    }
}