.time-picker-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-self: stretch;

    position: relative;

    .input-wrapper {
        display: flex;
        height: 48px;
        align-items: center;
        flex-shrink: 0;
        flex: 1 0;
        align-self: stretch;

        // overwrites default Safari iOS  styling
        input {
            height: 48px;
            -webkit-appearance: none;
            -moz-appearance: textfield;
            color: var(--color-base-contrast-high);
            text-align: left;
            background-color: white;

            &::-webkit-calendar-picker-indicator {
                background: none;
            }

            &::-webkit-date-and-time-value {
                text-align: left;
            }
        }

        
    }

    .time-icon {
        position: absolute;
        right: 12px;
        top: 36px; //optical alignment
        transition: all 300ms ease;
    }
}

.time-input-icon {
    border: none;
    background-color: var(--color-base);
    padding: 0;
}

input[type="date"]:before{
    color:rgba(53, 53, 53, 0.44);
    content:attr(placeholder);
    }
    
    input[type="date"].full:before {
    color:black;
    content:""!important;
    }
    
    input[type="time"]:before{
    color:rgba(53, 53, 53, 0.44);
    content:attr(placeholder);
    }
    
    input[type="time"].full:before {
    color:black;
    content:""!important;
    }