.employees-section-wrapper {
    display: flex;
    padding: var(--margin-l, 32px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--margin-base, 24px);
    align-self: stretch;

    &.noTitle {
        padding-top: var(--margin-use-cases-top-of-page, 16px) ;
        
    }

    .sub-body {
        margin: 0 auto;
    }

    .employees-top-block {
        display: flex;
        padding: 0px var(--margin-base, 24px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--margin-base, 24px);
        align-self: stretch;

        .leaderboard-title {
            margin-bottom: var(--padding-s);
        }

        .search-input-wrapper {
            display: flex;
            align-items: center;
            gap: var(--margin-xs, 8px);
            flex-shrink: 0;
            align-self: stretch;

            .input-wrapper {
                display: flex;
                align-self: stretch;
                flex: 1 0 0;

                .input-main {
                    margin: 0;
                    height: 40px;
                }
            }

            .btn-main {
                display: flex;
                padding: 11px 16px 12px 16px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                width: fit-content;
                margin: 0;
            }
        }
    }

    .button-wrapper {
        display: flex;
        padding: 0px var(--margin-base, 24px);
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
    }

    .empty-list {
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        text-align: center;
    }
}