.tab {
    justify-content: center;
    background: var(--color-base);
    font-size: var(--button-s);
    font-weight: 800;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 1 0 0;
    flex-shrink: 0;
    gap: 8px;
    color: var(--color-base-contrast-high);
    border: none;
    transition: var(--transition-base);
    box-sizing: border-box;
    padding: var(--padding-xs) var(--padding-s);
}

.tab-pill {
    border-radius: var(--roundedness-base);
    background: transparent;

    &.active {
        background: var(--color-secondary-yellow, #FC0);
    }
}

.tab-line {
    color: var(--color-base-contrast-high);
    position: relative;

    &.inactive {
        opacity: .5;
    }

    &:before {
        content: " ";
        position: absolute;
        bottom: 0;
        height: 0px;
        width: 100%;
        background: var(--color-base);
        transition: var(--transition-base);
    }
}

.tab-line.active {
    &:before {
        height: 3px;
        background: var(--color-primary);
    }
}

.tabs-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.10);
    background: var(--color-base);

    &.line {
        position: sticky;
        z-index: 99;
        top: var(--main-nav-height);
    }

    &.pill {
        box-shadow: none;
        border-radius: var(--roundedness-base);
        background: var(--color-base-contrast-lowest);
    }
}