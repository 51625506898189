.insights-card-wrapper {
    display: flex;
    width: 230px;
    min-width: 230px;
    max-width: 230px;
    padding: var(--margin-s, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--padding-s, 16px);
    position: relative;

    border-radius: var(--roundedness-base, 8px);
    background: var(--color-base-contrast-lowest, #F6F6F6);

    .time-ago {
        position: absolute;
        right: 16px;
        top: 16px;
    }

    .insights-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--padding-xs, 8px);
        align-self: stretch;
    }


}