.mention-card {
    background-color: var(--color-base-contrast-lowest);
    font-size: var(--body-s);
    width: 100%;
    padding: var(--margin-s);
    border-radius: var(--roundedness-base);

    .mention-card-header,
    .mention-card-footer {
        display: flex;
        align-items: center;
    }

    .mention-card-header {
        font-weight: 700;
        margin-bottom: var(--margin-s);

        .mention-card-header-name {
            margin-left: var(--margin-xs);
        }
    }

    p {
        margin-bottom: 0
    }

    .mention-card-justify-right {
        margin-left: auto;
    }

    .mention-card-footer {
        font-size: var(--body-xs);
        color: var(--color-base-contrast-med);

        .mention-card-justify-right {
            display: inherit;
            gap: calc(var(--margin-xs) / 2);
        }
    }
}