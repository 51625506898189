.page-404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: var(--margin-s);

    .img-404 {
        height: var(--icon-avatar);
        width: var(--icon-avatar);
        margin-bottom: var(--margin-s);
    }

    .header-404,
    .body-404 {
        margin-bottom: 0;
    }

    .header-404 {
        font-weight: 700;
        max-width: 300px;
    }

    .body-404 {
        color: var(--color-base-contrast-med);
    }
}