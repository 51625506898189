@import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap');

:root {
  /* --- | --- Figma Style & Variable Exports --- | ---*/
  /* --- Colors --- */
  --color-primary: #da281b;
  --color-secondary-yellow: #ffcc00;
  --color-tertiary-green: #00b191;
  --color-base: #ffffff;
  --color-base-contrast-high: #292929;
  --color-base-contrast-low: #e1e1e1;
  --color-base-contrast-lowest: #f6f6f6;
  --color-base-contrast-med: #666666;

  /* --- Added in Dev (Not in Figma) --- */
  --color-dark-red: #BF281D;
  --color-light-red: #FBEAE8;
  --color-dark-yellow: #DFB406;
  --color-light-yellow: #FFFAE6;
  --colors-overlay: rgba(41, 41, 41, 0.2);
  --colors-svg-filter-primary: invert(30%) sepia(40%) saturate(5271%) hue-rotate(347deg) brightness(84%) contrast(105%);
  --colors-svg-filter-base: invert(100%) sepia(1%) saturate(377%) hue-rotate(227deg) brightness(112%) contrast(100%);

  /* --- Sizes --- */
  /* Margin */
  --margin-xxs: 4px;
  --margin-xs: 8px;
  --margin-s: 16px;
  --margin-base: 24px;
  --margin-l: 32px;
  --margin-use-cases-top-of-page: var(--margin-s);

  /* Padding */
  --padding-xxs: 4px;
  --padding-xs: 8px;
  --padding-s: 16px;
  --padding-base: 24px;
  --padding-l: 32px;
  --padding-container: var(--padding-l) var(--padding-base);
  --padding-container-small: var(--padding-s) var(--padding-base);
  --padding-container-xsmall: var(--padding-xs) 10px;

  /* Radius */
  --roundedness-s: 4px;
  --roundedness-base: 8px;
  --roundedness-l: 16px;
  --roundedness-tag: 100px;
 
  /* Screen sizes */
  --screen-size-height-min: 568px;
  --screen-size-height-max: 932px;
  --screen-size-width-min: 320px;
  --screen-size-width-max: 430px;

  /* Text styles */
  --font-family-inter: 'Inter';
  --heading-xxl-mobile: 30px;
  --heading-xl-mobile: 24px;
  --heading-l-mobile: 20px;
  --heading-m-mobile: 18px;
  --heading-s-mobile: 15px;
  --body-l: 18px;
  --body-m: 16px;
  --body-s: 14px;
  --body-xs: 12px;
  --body-xxs: 8px;
  --button-l: 14px;
  --button-m: 14px;
  --button-s: 12px;
  --avatar-l: 40px;

  /* Effect styles */
  --shadow-base: 0px 16px 24px rgba(0, 0, 0, 0.1);
  --shadow-s: 0px 4px 12px rgba(0, 0, 0, 0.25);

  /* --- Navigation --- */
  --main-nav-height: 64px;

  /* --- Icons (Added in dev, not in figma) --- */
  --icon-avatar-l: 160px;
  --icon-avatar: 80px;
  --icon-l: 32px;
  --icon-base: 24px;
  --icon-m: 20px;
  --icon-s: 16px;

  /* --- Buttons (Added in dev, not in figma) --- */
  --button-height-l: 48px;
  --button-height-m: 40px;
  --button-height-s: 32px;

  /* --- Transitions --- */
  --transition-base: all 300ms ease;
  --transition-button: all 150ms cubic-bezier(.42,.97,.52,1.49);
  --animation-fadeInUp: fadeInUp 300ms ease;
  --animation-fadeOutUp: fadeOutUp 300ms ease;
  --animation-fadeInGrow: fadeInGrow 800ms cubic-bezier(.23,.93,.76,1.45);
  --animation-shake: shake 5000ms cubic-bezier(.42, .97, .99, 1.49) 5000ms;
  --animation-spin-infinite: spin 1000ms linear;
  --animation-grow-infinite: grow 1000ms linear;
}

.App {
  /* text-align: center; */
  font-family: var(--font-family-inter);
  line-height: normal;

  /* For sticky footer */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.profilePhoto {
  width: 80px;
  height: 80px;
  float: right;
}

/* forms */
.amplify-label,
.amplify-text {
  text-align: left;
  font-size: 12px;
  font-weight: bold;
}

.amplify-input,
.amplify-scrollview {
  text-align: left;
}


/* Remove default iOS color on Buttons */
input:is([type="button"], [type="submit"], [type="reset"]),
input[type="file"]::file-selector-button,
button {
  color: inherit;
}


/* Scrollbar Styling */
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}


::-webkit-scrollbar-track {
  background: var(--color-base-contrast-lowest);
}


::-webkit-scrollbar-thumb {
  border-radius: var(--roundedness-l);
  background: var(--color-secondary-yellow);
}


::-webkit-scrollbar-thumb:hover {
  background: var(--color-dark-yellow);
}

/* Used for handling closing a select or pop up when clicked outside of element [Used in Select & Time Picker] */
.onCloseOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.01);
}


/* -- Animations -- */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(2rem);
  }
}

@keyframes fadeInGrow {
  0% {
    opacity: 0;
    transform: scale(.6);
  }

  10% {
    opacity: 0;
    transform: scale(.6);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shake {
  0% {
    transform: rotate(0) scale(1);
  }

  2.5% {
    transform: rotate(0) scale(1);

  }

  5% {
    transform: rotate(0) scale(1);
    transform: rotate(-5deg) scale(1.2);
  }

  7.5% {
    transform: rotate(8deg) scale(1.2);

  }

  10% {
    transform: rotate(-10deg) scale(1.2);
  }

  12.5% {
    transform: rotate(6deg) scale(1.2);

  }

  15% {
    transform: rotate(0) scale(1.2);
  }

  17.5% {
    transform: rotate(0) scale(1.2);

  }

  20% {
    transform: rotate(0) scale(1);
  }

  25% {
    transform: rotate(0) scale(1);
  }
}

@keyframes spin{
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes grow {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.5);
  }
  100% {
    transform: scale(1);
}
}