.take-profile-photo-wrapper {
    width: 100%;

    .photo-input-wrapper {
        position: relative;
        height: 240px;
        border: 1px solid var(--color-secondary-yellow);
        border-radius: var(--roundedness-base);
        overflow: hidden;
        
        .photo-input-label {
            cursor: pointer;
            background: url('../../assets/images/material/Camera.svg') 50% 50% no-repeat;
            transition: all 150ms cubic-bezier(.42,.97,.52,1.49);
            text-align: center;

            &:active {
                background-color: var(--color-light-yellow);
                border-color: var(--color-dark-yellow);
                transform: scale(1.05);
            }
        }

        #photo-input-thumbnail {
            display: none; /* Prevents the alt text appearing when there is no content */
            position: absolute;
            top: 0;
            left: 0;
            background-size: cover;
            background-position: 50% 50%;
        }

        .photo-input-label,
        #photo-input-thumbnail {
            height: 100%;
            width: 100%;
        }
        
        #task-photo-input,
        #hiddenText {
            visibility: hidden;
        }
    }
}