.footer {
    margin-top: auto; // For sticky footer

    hr {
        margin: 0;
    }

    .footer-wrapper {
        font-family: var(--font-family-inter);
        font-size: var(--body-s);
        padding: var(--padding-container);

        .btn-main .btn-content {
            color: var(--color-base-contrast-high);
        }

        #copyrights-line {
            padding-top: 10px;
        }

        .footer-btns-wrapper {
            // Resets button margin to even out spacing with content above
            margin-top: calc(var(--margin-xs) * -1);
        }

        .privacy-terms {
            padding: 32px 0;
            font-size: var(--body-s);
            text-align: center;

            .privacy-terms-link {
                font-size: var(--body-s);
                text-align: center;

                &:first-child {
                    padding-left: 0px;
                    // border-right: 1px solid var(--color-base-contrast-low); // Vertical divider between footer links
                }
            }
        }
    }
}