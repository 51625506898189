.isUser-badge {
    position: absolute;
    display: flex;
    width: 32px;
    padding: 2px 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: var(--color-secondary-yellow, #FC0);
    bottom: -7px;

    span {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        color: var(--color-base-contrast-high, #292929);
        text-align: center;
        text-overflow: ellipsis;
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;
    }
}