.spotlight-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    position: relative;
    min-width: 325px;
    max-width: 350px;

    .spotlight-top {
        display: flex;
        height: 164px;
        padding: var(--margin-s, 16px);
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: var(--padding-s, 16px);
        align-self: stretch;
        color: var(--color-base);

        border-radius: var(--roundedness-base, 8px) var(--roundedness-base, 8px) 0px 0px;
        background: var(--color-primary, #DA281B);

        .avatar-outer-wrapper {
            .avatar-wrapper {
                box-shadow: var(--shadow-s);
            }
        }

        .spotlight-text-wrapper {
            .spotlight-title {
                color: var(--color-base);
                margin-bottom: var(--margin-xxs);
            }
        }
    }

    .spotlight-bottom {
        display: flex;
        padding: 10px;
        display: flex;
        align-items: center;
        gap: var(--padding-xs, 8px);
        align-self: stretch;
        justify-content: space-between;

        border-radius: 0px 0px var(--roundedness-base, 8px) var(--roundedness-base, 8px);
        background: var(--color-base-contrast-lowest, #F6F6F6);

        .shout-outs {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: var(--padding-xxs, 4px);

            .shout-out-icon {
                width: 20px;
                height: 20px;
            }
        }

        .btn-main {
            max-width: fit-content;
            margin: 0;
            height: 28px;

            .btn-content {
                flex-direction: row-reverse;
            }
        }
    }

}