.mentions-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--margin-base, 24px);
    padding: var(--margin-l, 32px) var(--margin-base, 24px);
    align-items: center;
    align-self: stretch;

    .mentions-text-block {
        display: flex;
        flex-direction: column;
        align-self: stretch;
    }

    .mentions-list-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0;
        align-items: center;
        gap: var(--padding-s, 16px);
        align-self: stretch;
    }
}

.no-mentions-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: var(--padding-s);
    padding: 16px 24px 32px 24px;
    border-radius: var(--roundedness-base, 8px);
    border: 1px solid var(--color-base-contrast-low, #E1E1E1);
    align-self: stretch;
    
    img {
        width: var(--icon-avatar);
        height: var(--icon-avatar);
        margin-bottom: var(--padding-xs);
    }

}