// leaderboard List Item
.leaderboard-list-item-wrapper {
  display: flex;
  padding: var(--margin-s, 16px);
  align-items: center;
  gap: var(--padding-s, 16px);
  align-self: stretch;

  border-radius: var(--roundedness-base, 8px);
  border: 1px solid var(--color-base-contrast-low, #E1E1E1);
  background: var(--color-base, #FFF);

  .left-wrapper {
    display: flex;
    height: 40px;
    align-items: center;
    gap: var(--padding-xs, 8px);
    flex: 1 0 0;
  }

  .right-wrapper {
    display: flex;
    height: 40px;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    font-weight: 700;

    .detail {
      color: var(--color-base-contrast-high, #292929);
      text-align: right;
      font-size: var(--body-base);
      font-weight: 700;
      margin: 0;

      &.trophies {
        font-size: 22px;
      }
    }
  }
}

.avatar-icon {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
}

.trophy-icon {
  width: 24px;
  height: 24px;
}

.leaderboard-item-label {
  display: flex;
  align-items: center;
  align-self: stretch;
  color: var(--color-base-contrast-high);
  font-feature-settings: 'clig' off, 'liga' off;
  margin: 0;
  font-size: var(--body-l);
  font-weight: 700;
}

.leaderboard-item-detail {
  align-self: stretch;
  color: var(--color-base, #FFF);
  margin: 0;
  font-size: var(--body-s);
  font-weight: 400;
}