.loading-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100000;

    .loading-overlay {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.3);
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;

            .loading-icon {
                position: relative;
                width: 64px;
                height: 64px;
                animation: var(--animation-grow-infinite);
            animation-iteration-count: infinite;
            }
    }
}