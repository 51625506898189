.reject-task-wrapper {

    h1 {
        margin-bottom: var(--margin-l);
    }
    
    .reject-task-modifier-wrapper {
        margin: var(--margin-base) 0;

        .wrapper-label {
            margin-bottom: var(--margin-xs);
        }

        .radio-fieldset {
            display: flex;
            flex-direction: column;
            gap: var(--margin-xs);

            .reject-task-input-label {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: var(--padding-s);
                border: 1px solid var(--color-base-contrast-low);
                border-radius: var(--roundedness-base);
                cursor: pointer;
            }

            .rejection-styled-radio {
                position: absolute;
                opacity: 0;

                &:checked + label {
                    border-color: var(--color-base-contrast-high);
                }
            }
        }
    }

    .rejection-reason-wrapper {
        margin-bottom: var(--margin-base);

        #rejection-reason-textbox, 
        .label {
            margin-bottom: var(--margin-xxs);
        }
    
        #rejection-reason-textbox {
            width: 100%;
            height: 115px;
            padding: var(--padding-xs);
            border-radius: var(--roundedness-s);
            border-color: var(--color-base-contrast-low);
        }
    
        .optional-tag {
            font-size: var(--body-xs);
            color: var(--color-base-contrast-med);
            margin-left: var(--margin-xs);
        }

        .textbox-bottom-text {
            display: flex;
            justify-content: space-between;
        }
    }
}