.breadcrumb-wrapper {
    font-size: var(--body-m);
    margin-bottom: var(--margin-s);
    display: flex;
    height: 24px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    .breadcrumb-divider {
        margin: 0 var(--margin-xs);
    }

    .breadcrumb-link {
        text-transform: capitalize;
    }
}