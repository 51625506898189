.task-summary-wrapper {
    background-color: var(--color-base-contrast-lowest);
    border-radius: var(--roundedness-base);
    overflow: hidden;

    .summary-content-wrapper {
        padding: var(--padding-s);
        text-align: center;

        .summary-content-modifier-list {
            margin-bottom: var(--margin-base);
        }
    }

    .points-text {
        font-size: 64px;
        font-weight: 700;
        color: var(--color-tertiary-green);
        margin-top: var(--margin-xs);
    }
}

.summary-modifier-row {
    display: flex;
    align-items: center;
    gap: var(--margin-xxs);
    margin-bottom: var(--margin-xs);

    .summary-modifier-icon {
        height: var(--icon-s);
        width: var(--icon-s);
    }

    .summary-modifier-points {
        margin-left: auto;

        &.negative {
            color: var(--color-primary);
        }
    }
}