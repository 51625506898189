.team-card {
    display: flex;
    padding: var(--margin-l, 32px) var(--margin-base, 24px);
    flex-direction: column;
    align-items: center;
    gap: var(--padding-base, 24px);
    align-self: stretch;

    .team-card-inner {
        display: flex;
        padding: var(--padding-base, 24px);
        flex-direction: column;
        align-items: center;
        gap: var(--padding-base, 24px);
        align-self: stretch;
        border-radius: var(--roundedness-l, 16px);
        background: var(--color-base-contrast-lowest, #F6F6F6);
        text-align: center;
    }
}

// Banner

.team-banner-wrapper {
    display: flex;
    padding: var(--margin-s, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--padding-s, 16px);
    align-self: stretch;

    border-radius: var(--roundedness-l, 16px);
    background: var(--color-base-contrast-high, #292929);

    .team-banner-left {
        display: flex;
        align-items: center;
        gap: var(--padding-xs, 8px);
        flex: 1 0 0;

        .team-banner-img {
            width: 40px;
            height: 40px;
            animation: var(--animation-shake);
            animation-iteration-count: infinite;
        }

        .team-banner-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 2px;
            flex: 1 0 0;
            align-self: stretch;

            .heading-s {
                color: var(--color-base);
            }

            .xs {
                color: var(--color-primary);
            }
        }
    }
    .btn-main {
        max-width: fit-content;
    
        .btn-content {
            flex-direction: row-reverse;
        }
    }


}

