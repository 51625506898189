.leaderboard-avatar-wrapper {
    width: 32px;
    height: 32px;
    display: flex;
    flex-shrink: 0;
    border-radius: 100px;
    background: var(--color-base-contrast-high);
    color: var(--color-base);
    position: relative;
    text-align: center;
    justify-content: center;
    align-items: center;

    &.isUser {
        background: var(--color-primary, #DA281B);
    }

    .leaderboard-item-label {
        font-size: var(--heading-l-mobile);
        text-align: center;
        color: var(--color-base);
        font-weight: 700;
    }

}