main {
    margin-top: var(--main-nav-height)
}

.page-padding {
    padding: var(--padding-container);
}

.padding-base {
    padding: var(--padding-base);
}

.btn-link {
    font-weight: 700;
    color: var(--color-primary);
    text-decoration: none;

    :hover {
        color: var(--color-dark-red) !important;
    }
    
}

a   {
    font-weight: 700;
    color: var(--color-primary);
    text-decoration: none;

    &:hover {
        color: var(--color-dark-red) !important;

    }
}