.taskAdmin {
    font-size: 12px;
    width: 100%;
    border-collapse: initial;

    tr {
        margin-bottom: 4px;
    }
    td {
        background-color: #eee;
        padding: 4px;
        margin-bottom: 4px;
    }

}