.accordion {
    font-family: var(--font-family-inter);
    font-size: var(--body-s);
    padding: var(--margin-s);
    margin: var(--margin-s) 0;
    border: 1px solid var(--color-base-contrast-low);
    border-radius: var(--roundedness-base);

    &:last-child {
        margin-bottom: 0;
    }

    &.open {
        .accordion-arrow {
            transform: rotate(-180deg);
        }

        .accordion-content-wrapper {
            height: 100%;
            margin-top: var(--margin-s);
        }
    }

    &.close {
        .accordion-arrow {
            transform: rotate(0);
        }
    }

    .accordion-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        transition: margin .3s linear;

        .accordion-label {
            font-weight: 700;
        }

        .accordion-arrow-wrapper {
            text-align: center;
            width: var(--icon-base);
            height: var(--icon-base);
            margin-left: var(--margin-s);

            .accordion-arrow {
                transition: transform .2s linear;
            }
        }
    }

    .accordion-content-wrapper {
        margin-top: 0;
        transition: all .2s linear;
        height: 0px;
        overflow: hidden;
        
        .accordion-content {
            color: var(--color-base-contrast-med);
        }
    }
}