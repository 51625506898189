.required-page {

    .required-page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: var(--margin-base);

        .page-heading,
        .required-name {
            font-weight: 700;
        }

        .page-heading {
            font-size: var(--heading-xl-mobile);
        }

    }

    .required-content {
        display: flex;
        flex-direction: column;

        .top-block {
            margin-bottom: var(--margin-base);
        }

        .terms-content {
            line-height: 145%;

            ol ul {
                list-style-type: initial;
            }

            .terms-content-list {
                padding-left: var(--padding-base);
                li {
                    margin-bottom: var(--padding-s);

                    span {
                        font-weight: 800;
                        margin-bottom: var(--padding-xs);
                        &.red {
                            color: var(--color-primary);
                        }
                    }

                    ul {
                        margin-top: 8px;
                        &.task-row {
                            padding-left: var(--padding-s);
                            border: 1px solid var( --color-base-contrast-low);
                            padding-top: var(--padding-xs);
                            border-radius: var(--roundedness-base);
                            margin: var(--padding-xs) 0 var(--padding-base) 0;
                            
                            li {
                                margin-bottom: var(--padding-xs);
                                margin-bottom: var(--padding-xs);
                                list-style-type: none;
                            }
                        }
                        &.prize-row {
                            padding-left: var(--padding-l);
                            border: 1px solid var( --color-base-contrast-low);
                            padding-top: var(--padding-s);
                            border-radius: var(--roundedness-base);
                            margin: var(--padding-s) 0 var(--padding-base) 0;
                            
                            li {
                                margin-bottom: var(--padding-s);
                                margin-bottom: var(--padding-s);
                            }
                        }
                    }
                }
            }
        }


        h1,
        h2,
        h2,
        h4,
        h5,
        h6,
        p {
            margin-bottom: var(--margin-base);
        }
    }
}

